import {zodResolver} from '@hookform/resolvers/zod';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {z} from 'zod';
import BillingInformation from './BillingInformation.js';
import CertificateOptions from './CertificateOptions.js';
import ShippingInformation from './ShippingInformation.js';
import {addressSchema} from '@/components/AddressFieldset/AddressFieldset';
import CreditCardFieldset from '@/components/CreditCardFieldset';
import {creditCardSchema} from '@/components/CreditCardFieldset/CreditCardFieldset';
import useDispatchAcceptJs, {AcceptJsError} from '@/hooks/useDispatchAcceptJs';
import useHandleMutation from '@/hooks/useHandleMutation';
import {useCreateGiftCertificateMutation} from '@/mutations/gift-certificate';
import type {GiftCertificate, ShippingOption} from '@/types/gift-certificate';
import {parseAmount} from '@/utils/parse.js';
import {errorMap, phoneNumberSchema} from '@/utils/zod';

const shippingToRecipientByMail = z.object({
    recipientAddress: addressSchema,
});

const shippingToRecipientByEmail = z.object({
    recipientEmailAddress: z.string().trim().email(),
});

const shippingToCustomerByMail = z.object({
    shippingAddressSameAsBillingAddress: z.boolean(),
    shippingAddress: addressSchema.optional(),
});

const schema = z.object({
    from: z.string().trim(),
    to: z.string().trim(),
    amount: z.string().trim(),
    phoneNumber: phoneNumberSchema,
    emailAddress: z.string().trim().email(),
    billingAddress: addressSchema,
    creditCard: creditCardSchema,
})
    .and(z.discriminatedUnion(
        'deliveryOption',
        [
            z.object({
                deliveryOption: z.literal('toRecipientByMail'),
                shippingInformation: shippingToRecipientByMail,
            }),
            z.object({
                deliveryOption: z.literal('toRecipientByEmail'),
                shippingInformation: shippingToRecipientByEmail,
            }),
            z.object({
                deliveryOption: z.literal('toCustomerByMail'),
                shippingInformation: shippingToCustomerByMail,
            }),
            z.object({
                deliveryOption: z.literal('toCustomerByEmail'),
            }),
        ]
    ));

export type GiftCertificateFormValues = z.infer<typeof schema>;

const GiftCertificateForm = () : JSX.Element => {
    const navigate = useNavigate();
    const handleMutation = useHandleMutation();
    const dispatchAcceptJs = useDispatchAcceptJs();
    const createGiftCertificateMutation = useCreateGiftCertificateMutation();
    const [shippingOption, setShippingOption] = useState<ShippingOption>('toRecipientByMail');

    const form = useForm<GiftCertificateFormValues>({
        resolver: zodResolver(schema, {errorMap}),
        defaultValues: {
            to: '',
            deliveryOption: 'toRecipientByMail',
            shippingInformation: {
                // @ts-expect-error default value non-default delivery option
                shippingAddressSameAsBillingAddress: true,
            },
        },
    });

    const deliveryOption = form.watch('deliveryOption');

    useEffect(() => {
        setShippingOption(deliveryOption);
    }, [deliveryOption]);

    const handleSubmit = async (values : GiftCertificateFormValues) => {
        const {amount: rawAmount, creditCard, ...rest} = values;
        const [expirationMonth, expirationYear] = creditCard.expirationDate.split('/');
        const amount = parseAmount(rawAmount);

        if (!amount) {
            throw new Error('Gift Certificate amount is not set');
        }

        const cardData = {
            cardNumber: creditCard.cardNumber,
            month: expirationMonth,
            year: expirationYear,
            cardCode: creditCard.cvv,
            fullName: `${values.billingAddress.firstName} ${values.billingAddress.lastName}`,
            zip: values.billingAddress.zipCode,
        };

        let opaqueAuthorizeNetData;

        try {
            opaqueAuthorizeNetData = await dispatchAcceptJs(cardData);
        } catch (error) {
            if (error instanceof AcceptJsError) {
                form.setError('creditCard.cardNumber', {type: 'custom', message: error.message});
            }

            return;
        }

        const customerGiftCertificate : GiftCertificate = {
            ...rest,
            opaqueAuthorizeNetData: {
                descriptor: opaqueAuthorizeNetData.opaqueData.dataDescriptor,
                value: opaqueAuthorizeNetData.opaqueData.dataValue,
            },
            amount,
        };

        const mutationResult = await handleMutation(createGiftCertificateMutation, customerGiftCertificate);

        if (mutationResult.success) {
            form.reset();
            navigate({
                pathname: '/purchase-gift-certificate/success',
            });
        }
    };

    return (
        <form onSubmit={form.handleSubmit(handleSubmit)} noValidate>
            <CertificateOptions form={form}/>
            <BillingInformation form={form}/>
            <ShippingInformation form={form} shippingOption={shippingOption}/>
            <Typography variant="h6" sx={{my: 2}}>Payment Information</Typography>
            <CreditCardFieldset prefix="creditCard" form={form}/>
            <LoadingButton
                type="submit"
                variant="contained"
                loading={createGiftCertificateMutation.isLoading}
                sx={{mt: 2}}
            >
                <span>Purchase Gift Certificate</span>
            </LoadingButton>
        </form>
    );
};

export default GiftCertificateForm;
