import type {IconButtonProps} from '@mui/material';
import {IconButton, styled} from '@mui/material';

type ExpandMoreProps = IconButtonProps & {
    expand : boolean;
};

const ExpandMore = styled((props : ExpandMoreProps) => {
    const {expand, ...rest} = props;
    return <IconButton {...rest}/>;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default ExpandMore;
