import {zodResolver} from '@hookform/resolvers/zod';
import {LoadingButton} from '@mui/lab';
import {Paper, Typography} from '@mui/material';
import {RhfRadioGroup} from 'mui-rhf-integration';
import {useSnackbar} from 'notistack';
import type {ReactNode} from 'react';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import {useApplyMembershipDonationMutation} from '@/mutations/cart.js';
import type {Cart} from '@/types/cart.js';

const amountOptions = [
    {value: '7500', label: '$75 Individual Membership'},
    {value: '15000', label: '$150 Household of Note Membership'},
    {value: '25000', label: '$250 Opening Act'},
    {value: '50000', label: '$500 Headliner'},
    {value: '1000000', label: '$1,000 Fold Legend'},
];

const donationSchema = z.object({
    amount: z.string(),
});

type DonationFormValues = z.infer<typeof donationSchema>;

type Props = {
    cart : Cart;
};

const MembershipDonation = ({cart} : Props) : ReactNode => {
    const applyMembershipDonationMutation = useApplyMembershipDonationMutation(cart.id);
    const {enqueueSnackbar} = useSnackbar();
    const form = useForm<DonationFormValues>({
        resolver: zodResolver(donationSchema),
    });

    const handleSubmit = (values : DonationFormValues) => {
        applyMembershipDonationMutation.mutate({
            amount: parseInt(values.amount, 10),
        }, {
            onError: error => {
                enqueueSnackbar(error.message, {variant: 'error'});
            },
        });
    };

    return (
        <Paper sx={{flexBasis: 400, p: 2}}>
            <Typography>
                Become a member today. Donations of $75 and above include a 1 year membership.
            </Typography>

            <form onSubmit={form.handleSubmit(handleSubmit)} noValidate>
                <RhfRadioGroup
                    sx={{mt: 4}}
                    control={form.control}
                    name="amount"
                    label="Compare all membership levels"
                    options={amountOptions}
                />

                <LoadingButton
                    sx={{mt: 2}}
                    loading={false}
                    disabled={!form.formState.isValid}
                    variant="contained"
                    type="submit"
                >
                    {cart.donationAmount ? 'Update' : 'Add'} membership donation
                </LoadingButton>
            </form>
        </Paper>
    );
};

export default MembershipDonation;
