import {zodResolver} from '@hookform/resolvers/zod';
import {ChronoUnit, convert, DateTimeFormatter, LocalDate, Period} from '@js-joda/core';
import {Locale} from '@js-joda/locale_en-us';
import RemoveIcon from '@mui/icons-material/Remove';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    LinearProgress,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Stack,
    TextField,
} from '@mui/material';
import {RhfTextField} from 'mui-rhf-integration';
import {RhfDatePicker} from 'mui-rhf-integration/date-picker';
import type {ReactNode} from 'react';
import {useEffect, useMemo, useState} from 'react';
import type {Control, FieldPath, FieldValues, PathValue} from 'react-hook-form';
import {Controller, useController, useForm} from 'react-hook-form';
import {z} from 'zod';
import {useCreateStudentMutation, useDeleteStudentMutation} from '@/mutations/student.js';
import {useStudentsQuery} from '@/queries/student.js';
import type {CourseClass} from '@/types/course-class';
import type {Student} from '@/types/student.js';
import {localDateFromDate} from '@/utils/datetime';

const dateFormatter = DateTimeFormatter.ofPattern('M/d/yyyy').withLocale(Locale.US);

const isEmailAddressEnabled = (dateOfBirth : unknown) => {
    if (!(dateOfBirth instanceof Date) || isNaN(dateOfBirth.getTime())) {
        return false;
    }

    return Period.between(
        localDateFromDate(dateOfBirth),
        LocalDate.now()
    ).get(ChronoUnit.YEARS) >= 18;
};

type StudentItemProps = {
    student : Student;
    minDateOfBirth : LocalDate | null;
    maxDateOfBirth : LocalDate | null;
    onSelect : (student : Student) => void;
    onRemove : () => void;
};

const StudentItem = ({
    student,
    minDateOfBirth,
    maxDateOfBirth,
    onSelect,
    onRemove,
} : StudentItemProps) : ReactNode => {
    const deleteStudentMutation = useDeleteStudentMutation();

    let disabledReason;

    if (minDateOfBirth && student.dateOfBirth.isBefore(minDateOfBirth)) {
        disabledReason = 'too old';
    } else if (maxDateOfBirth && student.dateOfBirth.isAfter(maxDateOfBirth)) {
        disabledReason = 'too young';
    }

    return (
        <ListItem
            key={student.id}
            disablePadding
            secondaryAction={(
                <IconButton
                    disabled={deleteStudentMutation.isLoading}
                    onClick={() => {
                        deleteStudentMutation.mutate({id: student.id});
                        onRemove();
                    }}
                >
                    <RemoveIcon/>
                </IconButton>
            )}
        >
            <ListItemButton
                disabled={disabledReason !== undefined}
                onClick={() => {
                    onSelect(student);
                }}
            >
                <ListItemText
                    primary={
                        `${student.firstName} ${student.lastName}`
                        + (student.emailAddress ? ` (${student.emailAddress})` : '')
                    }
                    secondary={
                        `Born ${dateFormatter.format(student.dateOfBirth)}`
                        + (disabledReason !== undefined
                            ? ` (${disabledReason})`
                            : ''
                        )
                    }
                />
            </ListItemButton>
        </ListItem>
    );
};

type Props<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
    control : Control<TFieldValues>;
    name : TName;
    courseClass : CourseClass;
    disabled ?: boolean;
};

const StudentSelector = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({control, name, courseClass, disabled} : Props<TFieldValues, TName>) : ReactNode => {
    const {field, fieldState} = useController({control, name});
    const selectedStudent = field.value as Student | null;

    const studentsQuery = useStudentsQuery();
    const [chooseDialogOpen, setChooseDialogOpen] = useState(false);
    const [formDialogOpen, setFormDialogOpen] = useState(false);
    const createStudentMutation = useCreateStudentMutation();

    const studentSchema = useMemo(() => {
        let dateOfBirthSchema = z.date();

        if (courseClass.minDateOfBirth) {
            dateOfBirthSchema = dateOfBirthSchema.min(
                convert(courseClass.minDateOfBirth.atStartOfDay()).toDate(),
                'Student is too old for this class'
            );
        }

        if (courseClass.maxDateOfBirth) {
            dateOfBirthSchema = dateOfBirthSchema.max(
                convert(courseClass.maxDateOfBirth.atStartOfDay().plusDays(1).minusSeconds(1)).toDate(),
                'Student is too young for this class'
            );
        }

        return z.object({
            firstName: z.string().trim().min(1),
            lastName: z.string().trim().min(1),
            dateOfBirth: dateOfBirthSchema,
            emailAddress: z.string().email().nullable(),
        }).refine(values => {
            return !(isEmailAddressEnabled(values.dateOfBirth) && !values.emailAddress);
        }, {message: 'Required', path: ['emailAddress']});
    }, [courseClass.maxDateOfBirth, courseClass.minDateOfBirth]);

    type StudentFormValues = z.infer<typeof studentSchema>;

    const form = useForm<StudentFormValues>({
        resolver: zodResolver(studentSchema),
        defaultValues: {
            emailAddress: null,
        },
    });

    const handleSubmit = (values : StudentFormValues) => {
        createStudentMutation.mutate({
            ...values,
            dateOfBirth: localDateFromDate(values.dateOfBirth).toString(),
        }, {
            onSuccess: student => {
                setFormDialogOpen(false);
                setChooseDialogOpen(false);
                field.onChange(student as PathValue<TFieldValues, TName>);
            },
        });
    };

    const watchDateOfBirth = form.watch('dateOfBirth');

    useEffect(() => {
        if (!isEmailAddressEnabled(watchDateOfBirth)) {
            form.setValue('emailAddress', null);
        }
    }, [watchDateOfBirth]);

    return (
        <>
            <TextField
                label="Student"
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <Button
                                onClick={() => {
                                    setChooseDialogOpen(true);
                                }}
                                disabled={disabled}
                            >
                                Choose
                            </Button>
                        </InputAdornment>
                    ),
                }}
                disabled={disabled}
                onClick={() => {
                    if (!disabled) {
                        setChooseDialogOpen(true);
                    }
                }}
                value={selectedStudent ? `${selectedStudent.firstName} ${selectedStudent.lastName}` : ''}
                fullWidth
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
            />

            <Dialog
                open={chooseDialogOpen}
                onClose={() => {
                    setChooseDialogOpen(false);
                }}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Choose student</DialogTitle>
                {!studentsQuery.data
                    ? <LinearProgress/>
                    : (
                        <List disablePadding>
                            {studentsQuery.data.length === 0
                                ? (
                                    <ListItem>
                                        <ListItemText>
                                            You haven't added any students yet.
                                        </ListItemText>
                                    </ListItem>
                                )
                                : studentsQuery.data.map(student => (
                                    <StudentItem
                                        key={student.id}
                                        student={student}
                                        minDateOfBirth={courseClass.minDateOfBirth}
                                        maxDateOfBirth={courseClass.maxDateOfBirth}
                                        onSelect={student => {
                                            field.onChange(student as PathValue<TFieldValues, TName>);
                                            setChooseDialogOpen(false);
                                        }}
                                        onRemove={() => {
                                            if (student.id === selectedStudent?.id) {
                                                field.onChange(null as PathValue<TFieldValues, TName>);
                                            }
                                        }}
                                    />
                                ))
                            }
                        </List>
                    )
                }
                <DialogActions>
                    <Button
                        onClick={() => {
                            setChooseDialogOpen(false);
                        }}
                        disabled={createStudentMutation.isLoading}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            form.reset();
                            setFormDialogOpen(true);
                        }}
                    >
                        Add student
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={formDialogOpen}
                onClose={() => {
                    setFormDialogOpen(false);
                }}
                maxWidth="sm"
                fullWidth
            >
                <Box
                    component="form"
                    noValidate
                    onSubmit={form.handleSubmit(handleSubmit)}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <DialogTitle>Add student</DialogTitle>
                    <DialogContent dividers>
                        <Stack spacing={2}>
                            <RhfTextField
                                control={form.control}
                                name="firstName"
                                label="First name"
                                required
                            />
                            <RhfTextField
                                control={form.control}
                                name="lastName"
                                label="Last name"
                                required
                            />
                            <RhfDatePicker
                                control={form.control}
                                name="dateOfBirth"
                                views={['year', 'month', 'day']}
                                openTo="year"
                                format="M.d.yyyy"
                                label="Date of birth"
                                textFieldProps={{
                                    required: true,
                                }}
                            />
                            <Controller
                                control={form.control}
                                name="dateOfBirth"
                                render={({field: {value}}) => {
                                    const enabled = isEmailAddressEnabled(value as unknown);

                                    return (
                                        <RhfTextField
                                            control={form.control}
                                            name="emailAddress"
                                            label="Email address"
                                            type="email"
                                            required={enabled}
                                            disabled={!enabled}
                                        />
                                    );
                                }}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setFormDialogOpen(false);
                            }}
                            disabled={createStudentMutation.isLoading}
                        >
                            Cancel
                        </Button>
                        <LoadingButton variant="contained" type="submit" loading={createStudentMutation.isLoading}>
                            Confirm
                        </LoadingButton>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
};

export default StudentSelector;
