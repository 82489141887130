import {ListItem, ListItemText} from '@mui/material';
import type {ReactNode} from 'react';
import {currencyFormatter} from '@/utils/format.js';

type Props = {
    label : string;
    secondary ?: string;
    amount : number;
    action ?: ReactNode;
};

const SummaryLineItem = ({label, secondary, amount, action} : Props) : ReactNode => {
    return (
        <ListItem
            secondaryAction={action}
        >
            <ListItemText primary={label} secondary={secondary}/>
            <ListItemText sx={{textAlign: 'right'}}>{currencyFormatter.format(amount / 100)}</ListItemText>
        </ListItem>
    );
};

export default SummaryLineItem;
