import {DateTimeFormatter} from '@js-joda/core';
import {Locale} from '@js-joda/locale_en-us';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {Box, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography} from '@mui/material';
import type {ReactNode} from 'react';
import {useDeleteReservationMutation, useUpdateReservationMutation} from '@/mutations/reservation.js';
import type {Cart, ReservedEventLineItem} from '@/types/cart.js';
import type {Price} from '@/types/event';
import type {Seat} from '@/types/reservation.js';
import {currencyFormatter} from '@/utils/format.js';

const dateFormatter = DateTimeFormatter.ofPattern('MM/dd/yyyy').withLocale(Locale.US);
const timeFormatter = DateTimeFormatter.ofPattern('h:mm a').withLocale(Locale.US);
const tableRegex = /^table([A-Z]+)$/;

type Props = {
    cart : Cart;
    item : ReservedEventLineItem;
};

const ReservedEventItem = ({cart, item} : Props) : ReactNode => {
    const updateReservationMutation = useUpdateReservationMutation();
    const deleteReservationMutation = useDeleteReservationMutation();
    const priceCategory : keyof Price = cart.membershipRank !== null ? 'member' : 'general';

    const removeSeat = (seat : Seat) => {
        if (item.reservation.seats.size === 1) {
            deleteReservationMutation.mutate({reservationId: item.reservation.id});
            return;
        }

        updateReservationMutation.mutate({
            reservationId: item.reservation.id,
            seats: [...item.reservation.seats.values()].filter(
                current => current.row !== seat.row || current.seatNumber !== seat.seatNumber
            ),
        });
    };

    return (
        <Box sx={{p: 2}}>
            <Typography fontWeight="bold">
                {item.event.title}
                {' - '}
                {item.event.date.format(dateFormatter)}
                {' '}
                {item.event.startTime.format(timeFormatter)}
                {' - '}
                {item.event.location.name}
            </Typography>

            <List disablePadding>
                {[...item.reservation.seats.values()].map(seat => {
                    const tableMatch = tableRegex.exec(seat.row);
                    let row;

                    if (tableMatch) {
                        row = `Table ${tableMatch[1]}`;
                    } else {
                        row = `Row ${seat.row}`;
                    }

                    const seatMeta = item.event.seats.get(`${seat.row}-${seat.seatNumber}`);
                    const price = (seatMeta?.price ?? item.event.price)[priceCategory];
                    const fee = seatMeta?.fee ?? item.event.fee;

                    return (
                        <ListItem
                            key={`${seat.row}-${seat.seatNumber}`}
                            secondaryAction={(
                                <IconButton
                                    edge="end"
                                    color="error"
                                    onClick={() => {
                                        removeSeat(seat);
                                    }}
                                >
                                    <RemoveCircleIcon/>
                                </IconButton>
                            )}
                        >
                            <ListItemIcon>
                                <ConfirmationNumberIcon/>
                            </ListItemIcon>
                            <ListItemText>
                                {row} / Seat {seat.seatNumber} /{' '}
                                {currencyFormatter.format(price / 100)}
                                {fee > 0 && ` (+${currencyFormatter.format(fee / 100)} fee)`}
                            </ListItemText>
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
};

export default ReservedEventItem;
