import {Box} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import type {ReactNode} from 'react';
import {Outlet} from 'react-router-dom';
import CheckoutButton from './CheckoutButton.js';
import UserMenu from './UserMenu.js';
import logo from '@/assets/logo.png';

const Layout = () : ReactNode => {
    return (
        <>
            <AppBar position="static" color="transparent">
                <Toolbar>
                    <Box
                        component="img"
                        src={logo}
                        alt="Old Town School of Folk Music"
                        sx={{height: 32, mr: 'auto'}}
                    />

                    <CheckoutButton/>
                    <UserMenu/>
                </Toolbar>
            </AppBar>
            <main>
                <Outlet/>
            </main>
        </>
    );
};

export default Layout;
