import {InputAdornment, Link, ToggleButton, ToggleButtonGroup} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {RhfSwitch, RhfTextField} from 'mui-rhf-integration';
import type {ReactNode} from 'react';
import {useEffect, useState} from 'react';
import type {UseFormReturn} from 'react-hook-form';
import {Controller} from 'react-hook-form';
import type {DonationFormValues} from './Donate.js';
import {createMaskedInput} from '@/utils/masked-input';

const singleDonationAmountOptions = [25, 50, 75, 150, 250]
    .map(item => ({value: item.toString(), label: `$${item}`}));
const monthlyDonationAmountOptions = [15, 25, 45, 90, 125]
    .map(item => ({value: item.toString(), label: `$${item}`}));
const annualDonationAmountOptions = [75, 150, 250, 500, 1000]
    .map(item => ({value: item.toString(), label: `$${item}`}));

const MaskedCustomAmountInput = createMaskedInput({
    mask: Number,
    scale: 0,
    thousandsSeparator: ',',
    max: 100_000,
});

type Props = {
    form : UseFormReturn<DonationFormValues>;
};

const DonationAmount = ({form} : Props) : ReactNode => {
    const [amountOptions, setAmountOptions] = useState(monthlyDonationAmountOptions);
    const [isRecurring, setIsRecurring] = useState<boolean>(true);

    useEffect(() => {
        form.setValue('recurringDonation', isRecurring);
    }, [isRecurring]);

    return (
        <Grid container spacing={2}>
            <RhfSwitch control={form.control} name="recurringDonation" sx={{display: 'none'}}/>
            <Grid xs={12} hidden={!isRecurring}>
                <Controller
                    control={form.control}
                    name="isSplitIntoMonthlyDonations"
                    render={({field: {value, onChange, ...fieldProps}}) => (
                        <ToggleButtonGroup
                            {...fieldProps}
                            value={value}
                            color={'primary'}
                            exclusive
                            fullWidth
                            onChange={(event, newValue : 'true' | 'false') => {
                                onChange(newValue);
                                setAmountOptions(
                                    newValue === 'true' ? monthlyDonationAmountOptions : annualDonationAmountOptions
                                );
                            }}
                        >
                            <ToggleButton
                                key='monthly'
                                value={'true'}
                            >
                                Monthly Contribution
                            </ToggleButton>
                            <ToggleButton
                                key='annually'
                                value={'false'}
                            >
                                Annual Contribution
                            </ToggleButton>
                        </ToggleButtonGroup>
                    )}
                />
            </Grid>
            <Grid xs={12}>
                <Controller
                    control={form.control}
                    name="amount"
                    render={({field: {value, onChange, ...fieldProps}}) => (
                        <ToggleButtonGroup
                            {...fieldProps}
                            value={value}
                            color={'primary'}
                            exclusive
                            fullWidth
                            onChange={(event, newValue : string) => {
                                onChange(newValue);
                            }}
                        >
                            {amountOptions.map(defaultDonationAmountOptions => (
                                <ToggleButton
                                    key={defaultDonationAmountOptions.value}
                                    value={defaultDonationAmountOptions.value}
                                >
                                    {defaultDonationAmountOptions.label}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    )}
                />
            </Grid>
            <Grid xs={12}>
                <RhfTextField
                    control={form.control}
                    label="Donation Amount"
                    name="amount"
                    InputProps={{
                        inputComponent: MaskedCustomAmountInput,
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    required
                    fullWidth
                />
            </Grid>
            <Grid xs={4}>
                <Link
                    href={'#'}
                    sx={{mt: 10, textDecoration: 'none'}}
                    onClick={() => {
                        setIsRecurring(!isRecurring);
                        form.resetField('isSplitIntoMonthlyDonations');
                        setAmountOptions(isRecurring ? singleDonationAmountOptions : monthlyDonationAmountOptions);
                    }}
                >
                    Make a {isRecurring ? 'one-time' : 'recurring'} donation
                </Link>
            </Grid>
        </Grid>
    );
};

export default DonationAmount;
