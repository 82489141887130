import type {UseQueryResult} from '@tanstack/react-query';
import {useQuery} from '@tanstack/react-query';
import useOidcFetch from '@/hooks/useOidcFetch.js';
import type {JsonApiDocument} from '@/types/json-api.js';
import {apiUrl} from '@/utils/api.js';

export const useUpcomingChargesQuery = () : UseQueryResult<string[]> => {
    const fetch = useOidcFetch();

    return useQuery(['upcoming-charges'], async ({signal}) => {
        const response = await fetch(apiUrl('/upcoming-charges').toString(), {
            signal,
        });

        if (!response.ok) {
            throw new Error('Unable to fetch upcoming charges');
        }

        const raw = await response.json() as JsonApiDocument<string[]>;
        return raw.data;
    });
};
