import {useAuth0} from '@auth0/auth0-react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {IconButton} from '@mui/material';
import type {ReactNode} from 'react';
import {useNavigate} from 'react-router-dom';
import useCart from '@/hooks/useCart.js';

const CheckoutButton = () : ReactNode => {
    const {isAuthenticated} = useAuth0();
    const {cart, isLoading} = useCart();
    const navigate = useNavigate();

    if (!isAuthenticated || (!isLoading && !cart)) {
        return null;
    }

    return (
        <IconButton
            disabled={isLoading}
            onClick={() => {
                navigate('/checkout');
            }}
        >
            <ShoppingCartIcon/>
        </IconButton>
    );
};

export default CheckoutButton;
