import {useCreateReservationMutation, useUpdateReservationMutation} from '@/mutations/reservation.js';
import type {Reservation} from '@/types/reservation.js';

export type AddSeatsToReservation = (
    eventId : string,
    row : string,
    seatNumbers : number[],
    reservation ?: Reservation
) => Promise<number>;

export default () : AddSeatsToReservation => {
    const createReservationMutation = useCreateReservationMutation();
    const updateReservationMutation = useUpdateReservationMutation();

    return async (eventId : string, row : string, seatNumbers : number[], reservation ?: Reservation) => {
        if (!reservation) {
            await createReservationMutation.mutateAsync({
                eventId,
                seats: seatNumbers.map(seatNumber => ({row, seatNumber})),
            });
            return seatNumbers.length;
        }

        const seats = [
            ...reservation.seats.values(),
            ...seatNumbers.map(seatNumber => ({row, seatNumber})),
        ];

        await updateReservationMutation.mutateAsync({
            reservationId: reservation.id,
            seats: seats,
        });

        return seats.length;
    };
};
