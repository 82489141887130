import Grid from '@mui/material/Unstable_Grid2';
import type {ReactNode} from 'react';
import {useEffect, useState} from 'react';
import {useDebouncedCallback} from 'use-debounce';
import NumberField from '@/components/NumberField';
import {useUpdateHandicappedMutation} from '@/mutations/reservation';
import type {Reservation} from '@/types/reservation';

type Props = {
    reservation : Reservation;
    validated : boolean;
};

const AccessibleSelector = ({reservation, validated} : Props) : ReactNode => {
    const [numberOfHandicapped, setNumberOfHandicapped] = useState(reservation.numberOfHandicapped);
    const [numberOfWheelchairs, setNumberOfWheelchairs] = useState(reservation.numberOfWheelchairs);
    const updateHandicappedMutation = useUpdateHandicappedMutation(reservation.id);

    useEffect(() => {
        setNumberOfHandicapped(reservation.numberOfHandicapped);
    }, [reservation.numberOfHandicapped]);

    useEffect(() => {
        setNumberOfWheelchairs(reservation.numberOfWheelchairs);
    }, [reservation.numberOfWheelchairs]);

    const handleUpdate = useDebouncedCallback(
        (numberOfHandicapped : number | null, numberOfWheelchairs : number | null) => {
            if (numberOfHandicapped !== null && numberOfWheelchairs !== null) {
                updateHandicappedMutation.mutate({
                    numberOfHandicapped,
                    numberOfWheelchairs,
                });
            }
        },
        1000,
    );

    return (
        <Grid container sx={{mt: 2}} spacing={2}>
            <Grid xs={12} md={6}>
                <NumberField
                    label="Handicapped attendees"
                    helperText="How many attendees are handicapped?"
                    value={numberOfHandicapped}
                    setValue={value => {
                        setNumberOfHandicapped(value);
                        handleUpdate(value, numberOfWheelchairs);
                    }}
                    minValue={0}
                    maxValue={reservation.seats.size}
                    sx={{width: '100%'}}
                    required
                    errorMessage={validated && numberOfHandicapped === null ? 'Required' : undefined}
                />
            </Grid>
            <Grid xs={12} md={6}>
                <NumberField
                    label="Required access"
                    helperText="How many will need access for a wheelchair or scooter?"
                    value={numberOfWheelchairs}
                    setValue={value => {
                        setNumberOfWheelchairs(value);
                        handleUpdate(numberOfHandicapped, value);
                    }}
                    minValue={0}
                    maxValue={reservation.seats.size}
                    sx={{width: '100%'}}
                    required
                    errorMessage={validated && numberOfWheelchairs === null ? 'Required' : undefined}
                />
            </Grid>
        </Grid>
    );
};

export default AccessibleSelector;
