import {zodResolver} from '@hookform/resolvers/zod';
import type {LocalDate} from '@js-joda/core';
import {DateTimeFormatter} from '@js-joda/core';
import {Locale} from '@js-joda/locale_en-us';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Alert,
    Backdrop,
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    MenuItem,
    Stack,
} from '@mui/material';
import Button from '@mui/material/Button';
import {RhfSwitch, RhfTextField} from 'mui-rhf-integration';
import {useSnackbar} from 'notistack';
import type {ReactNode} from 'react';
import {useEffect, useMemo, useState} from 'react';
import Countdown from 'react-countdown';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import {useAddToCartMutation, useUpdateCourseClassLineItemMutation} from '@/mutations/cart';
import StudentSelector from '@/pages/Checkout/CourseClass/StudentSelector.js';
import {getClassPriceCategory} from '@/pages/Checkout/utils.js';
import {useCourseClassQuery} from '@/queries/course-class';
import type {Cart, CourseClassLineItem} from '@/types/cart';
import {currencyFormatter} from '@/utils/format.js';
import {errorMap} from '@/utils/zod.js';

const dateTimeFormatter = DateTimeFormatter.ofPattern(
    'EEEE, MMMM d, yyyy – h:mm a'
).withLocale(Locale.US);

const baseSchema = z.object({
    student: z.object({
        id: z.string(),
        firstName: z.string(),
        lastName: z.string(),
        dateOfBirth: z.custom<LocalDate>(),
        emailAddress: z.string().nullable(),
    }),
    instrumentId: z.string().optional(),
    paidInInstallments: z.boolean().optional(),
});

type FormValues = z.infer<typeof baseSchema>;

type Props = {
    open : boolean;
    onClose : () => void;
    courseClassId : string;
    cart : Cart;
    lineItem ?: CourseClassLineItem;
};

const CourseClassDialog = ({open, onClose, courseClassId, cart, lineItem} : Props) : ReactNode => {
    const initialInstrumentId = lineItem?.instrumentId ?? undefined;
    const initialStudent = lineItem?.student ?? undefined;
    const initialPaidInInstallments = lineItem?.paidInInstallments ?? false;

    const courseClassQuery = useCourseClassQuery(courseClassId, {excludeLineItemId: lineItem?.id});
    const addToCartMutation = useAddToCartMutation(cart.id);
    const updateLineItemMutation = useUpdateCourseClassLineItemMutation(cart.id, lineItem?.id ?? '');
    const {enqueueSnackbar} = useSnackbar();
    const [saleStarted, setSaleStarted] = useState(Boolean(lineItem));
    const [saleEnded, setSaleEnded] = useState(false);

    const schema = useMemo(() => {
        if (!courseClassQuery.data) {
            return baseSchema;
        }

        return baseSchema.required({
            instrumentId: courseClassQuery.data.instruments ? true : undefined,
            paidInInstallments: courseClassQuery.data.paymentInstallments !== null ? true : undefined,
        });
    }, [courseClassQuery.data]);

    const form = useForm<FormValues>({
        resolver: zodResolver(schema, {errorMap}),
        defaultValues: {
            student: initialStudent,
            instrumentId: initialInstrumentId,
            paidInInstallments: initialPaidInInstallments,
        },
    });

    useEffect(() => {
        form.reset({
            student: initialStudent,
            instrumentId: initialInstrumentId,
            paidInInstallments: initialPaidInInstallments,
        });
    }, [form.reset, initialInstrumentId, initialStudent, initialPaidInInstallments]);

    if (courseClassQuery.isLoading) {
        return (
            <Backdrop open>
                <CircularProgress/>
            </Backdrop>
        );
    }

    if (courseClassQuery.isError) {
        return (
            <Dialog
                open={open}
                onClose={onClose}
                fullWidth
                maxWidth="xs"
            >
                <DialogContent>
                    <Alert severity="error">Failed to load class.</Alert>
                </DialogContent>
            </Dialog>
        );
    }

    const courseClass = courseClassQuery.data;

    const handleSubmit = (values : FormValues) => {
        if (lineItem) {
            updateLineItemMutation.mutate(
                {
                    studentId: values.student.id,
                    instrumentId: values.instrumentId ?? null,
                    paidInInstallments: values.paidInInstallments ?? false,
                },
                {
                    onSuccess: () => {
                        enqueueSnackbar('Class has been updated', {variant: 'success'});
                        onClose();
                    },
                    onError: () => {
                        enqueueSnackbar('Class could not be updated', {variant: 'error'});
                        void courseClassQuery.refetch();
                    },
                }
            );
            return;
        }

        addToCartMutation.mutate({
            type: 'course-class',
            courseClassId,
            studentId: values.student.id,
            instrumentId: values.instrumentId ?? null,
            paidInInstallments: values.paidInInstallments ?? false,
        }, {
            onSuccess: () => {
                enqueueSnackbar('Class has been added to your cart', {variant: 'success'});
                onClose();
            },
            onError: () => {
                enqueueSnackbar('Class could not be added to your cart', {variant: 'error'});
                void courseClassQuery.refetch();
            },
        });
    };

    const saleOpen = saleStarted && !saleEnded;
    const student = form.watch('student');
    const priceCategory = getClassPriceCategory(cart, student);
    const price = courseClass.price[priceCategory];
    const proratedPrice = courseClass.proratedPrice[priceCategory];
    const selectedInstrumentId = form.watch('instrumentId');

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="xs"
        >
            <Box
                component="form"
                noValidate
                onSubmit={form.handleSubmit(handleSubmit)}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <DialogTitle>{courseClass.title}</DialogTitle>
                <DialogContent dividers>
                    <Stack spacing={2}>
                        {!saleStarted && (
                            <Alert severity="info">
                                The class isn't selling yet. Sale opens on{' '}
                                {dateTimeFormatter.format(courseClass.saleStart.at)}.
                            </Alert>
                        )}

                        {saleEnded && (
                            <Alert severity="warning">
                                The class is not on sale anymore.
                            </Alert>
                        )}

                        <StudentSelector
                            control={form.control}
                            name="student"
                            courseClass={courseClass}
                            disabled={!saleOpen}
                        />

                        {courseClass.instruments && (
                            <RhfTextField
                                control={form.control}
                                name="instrumentId"
                                select
                                label="Instrument"
                                fullWidth
                                disabled={!saleOpen}
                            >
                                {courseClass.instruments.map(instrument => {
                                    const left = Math.max(
                                        0,
                                        instrument.available - instrument.booked - (instrument.reserved ?? 0)
                                    );

                                    return (
                                        <MenuItem
                                            key={instrument.id}
                                            value={instrument.id}
                                            disabled={left <= 0}
                                        >
                                            {instrument.name}
                                            {instrument.id !== selectedInstrumentId && ` (${left} left)`}
                                        </MenuItem>
                                    );
                                })}
                            </RhfTextField>
                        )}

                        {courseClass.paymentInstallments !== null && (
                            <FormControlLabel
                                control={
                                    <RhfSwitch
                                        control={form.control}
                                        name="paidInInstallments"
                                        disabled={!saleOpen}
                                    />
                                }
                                label={
                                    `Pay in ${courseClass.paymentInstallments} monthly installments of ${
                                        currencyFormatter.format(
                                            Math.ceil(
                                                Math.floor(
                                                    (proratedPrice ?? price) / courseClass.paymentInstallments
                                                ) / 100
                                            )
                                        )
                                    }`
                                }
                            />
                        )}

                        {!lineItem && (
                            <>
                                <Countdown
                                    date={courseClass.saleStart.atLocal}
                                    renderer={() => null}
                                    onComplete={() => {
                                        setSaleStarted(true);
                                    }}
                                />
                                <Countdown
                                    date={courseClass.saleEnd.atLocal}
                                    renderer={() => null}
                                    onComplete={() => {
                                        setSaleEnded(true);
                                    }}
                                />
                            </>
                        )}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <LoadingButton
                        loading={addToCartMutation.isLoading || updateLineItemMutation.isLoading}
                        type="submit"
                        disabled={!saleOpen}
                    >
                        {lineItem ? 'Save' : 'Add'}
                    </LoadingButton>
                </DialogActions>
            </Box>
        </Dialog>
    );
};

export default CourseClassDialog;
