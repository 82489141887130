import type {ReactNode} from 'react';
import {useSeatingChartContext} from './SeatingChart.js';
import SectionSelector from './SectionSelector.js';
import {calculateBlockSize, sectionSpacing} from './utils.js';
import type {Block} from '@/seating-layouts/index.js';

type Props = {
    x : number;
    y : number;
    block : Block;
};

const BlockSelector = ({x, y, block} : Props) : ReactNode => {
    const {layoutSize} = useSeatingChartContext();
    const blockSize = calculateBlockSize(block);

    return (
        <g transform={`translate(${x}, ${y})`}>
            <SectionSelector
                x={0}
                y={0}
                section={block.sections.left}
                sectionName="left"
                verticalAlign={block.verticalAlign}
                blockHeight={blockSize.total.height}
            />
            <SectionSelector
                x={layoutSize.leftWidth + sectionSpacing}
                y={0}
                section={block.sections.center}
                sectionName="center"
                verticalAlign={block.verticalAlign}
                blockHeight={blockSize.total.height}
            />
            <SectionSelector
                x={layoutSize.leftWidth + layoutSize.centerWidth + sectionSpacing * 2}
                y={0}
                section={block.sections.right}
                sectionName="right"
                verticalAlign={block.verticalAlign}
                blockHeight={blockSize.total.height}
            />
        </g>
    );
};

export default BlockSelector;
