import type {UseQueryResult} from '@tanstack/react-query';
import {useQuery} from '@tanstack/react-query';
import useOidcFetch from '@/hooks/useOidcFetch.js';
import {mapRawToStudent} from '@/mappers/student.js';
import type {JsonApiDocument} from '@/types/json-api.js';
import type {RawStudent, Student} from '@/types/student.js';
import {apiUrl} from '@/utils/api.js';

export const useStudentsQuery = () : UseQueryResult<Student[]> => {
    const fetch = useOidcFetch();

    return useQuery(['students'], async ({signal}) => {
        const response = await fetch(apiUrl('/students').toString(), {
            signal,
        });

        if (!response.ok) {
            throw new Error('Unable to fetch students');
        }

        const raw = await response.json() as JsonApiDocument<RawStudent[]>;
        return raw.data.map(mapRawToStudent);
    });
};
