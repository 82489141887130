import {Container, Divider, Link} from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import GiftCertificateForm from '@/pages/GiftCertificate/GiftCertificateForm/GiftCertificateForm';

const GiftCertificate = () : JSX.Element => {
    return (
        <Container sx={{py: 3}}>
            <Grid container spacing={3}>
                <Grid xs={12}>
                    <Typography variant="h5" sx={{mb: 2}}>Purchase a Gift Certificate</Typography>
                    <Typography>
                        From making musical memories together in{' '}
                        <Link
                            href="https://www.oldtownschool.org/classes/kids/wiggleworms/"
                            sx={{textDecoration: 'none'}}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Wiggleworms
                        </Link>
                        {' '}classes to trying something new in one of our{' '}
                        <Link
                            href="https://www.oldtownschool.org/classes/adults/"
                            sx={{textDecoration: 'none'}}
                            target="_blank"
                            rel="noreferrer"
                        >
                            adult music or dance
                        </Link>
                        {' '}classes, Old Town School gift certificates make perfect gifts!
                        Gift certificates can be purchased in any amount and redeemed for group classes,
                        private lessons, concert tickets, memberships,
                        or may be used in the Old Town School Music Store.
                    </Typography>
                    <Typography sx={{mt: 2}}>
                        Please note: gift certificates are not usable online for concert tickets -
                        you must call our front desk at 773-728-6000 to redeem your gift certificate.
                        Old Town School gift certificates cannot be used on third-party ticketing platforms
                        such as Mandolin or NoonChorus.
                    </Typography>
                    <Divider sx={{my: 2}}/>
                    <GiftCertificateForm/>
                </Grid>
            </Grid>
        </Container>
    );
};

export default GiftCertificate;
