import '@js-joda/timezone/dist/js-joda-timezone-2017-2027.js';
import {LocalDate, ZoneId} from '@js-joda/core';

export const serverZone = ZoneId.of('America/New_York');

export const localDateFromDate = (date : Date) : LocalDate => LocalDate.of(
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate()
);
