import memoize from 'nano-memoize';
import type {Block, SeatingLayout, SeatRow, Section, TableRow} from '@/seating-layouts/index.js';
import type {ReservedEvent} from '@/types/event.js';
import type {Reservation} from '@/types/reservation';

export const seatWidth = 28;
export const seatHeight = 28;
export const baseSpacing = 8;
export const seatPadding = baseSpacing * 0.25;
export const tableSpacing = baseSpacing * 3;
export const blockSpacing = baseSpacing * 3;
export const rowSpacing = baseSpacing;
export const sectionSpacing = baseSpacing * 5;
export const stageHeight = 70;
export const balconySeparatorHeight = 90;

export type Size = {
    width : number;
    height : number;
};

export const calculateSeatRowSize = memoize((row : SeatRow) : Size => {
    const numSeats = Math.abs(row.startNumber - row.endNumber) + 1;

    return {
        width: numSeats * seatWidth,
        height: seatHeight,
    };
});

export const calculateTableRowSize = memoize((row : TableRow) : Size => {
    const numTables = row.tables.length;

    return {
        width: (seatWidth * 2) * numTables + (numTables - 1) * tableSpacing,
        height: seatHeight * 2,
    };
});

export const calculateSectionSize = memoize((section : Section) : Size => {
    const rowSizes = section.rows.map(
        row => row.type === 'seats' ? calculateSeatRowSize(row) : calculateTableRowSize(row)
    );

    return {
        width: Math.max(0, ...rowSizes.map(size => size.width)),
        height: rowSizes.reduce((previous, size) => previous + size.height, 0) + (rowSizes.length - 1) * rowSpacing,
    };
});

export type SectionedSizes = {
    total : Size;
    leftWidth : number;
    centerWidth : number;
    rightWidth : number;
};

export const calculateBlockSize = memoize((block : Block) : SectionedSizes => {
    const left = calculateSectionSize(block.sections.left);
    const center = calculateSectionSize(block.sections.center);
    const right = calculateSectionSize(block.sections.right);

    return {
        total: {
            width: left.width + center.width + right.width + 2 * sectionSpacing,
            height: Math.max(left.height, center.height, right.height),
        },
        leftWidth: left.width,
        centerWidth: center.width,
        rightWidth: right.width,
    };
});

export const calculateFloorSize = memoize((blocks : Block[]) : SectionedSizes => {
    const blockSizes = blocks.map(block => calculateBlockSize(block));

    return {
        total: {
            width: Math.max(0, ...blockSizes.map(sizes => sizes.total.width)),
            height: blockSizes.reduce(
                (previous, size) => previous + size.total.height,
                0
            ) + (blockSizes.length - 1) * blockSpacing,
        },
        leftWidth: Math.max(0, ...blockSizes.map(sizes => sizes.leftWidth)),
        centerWidth: Math.max(0, ...blockSizes.map(sizes => sizes.centerWidth)),
        rightWidth: Math.max(0, ...blockSizes.map(sizes => sizes.rightWidth)),
    };
});

export const calculateLayoutSize = memoize((layout : SeatingLayout) : SectionedSizes => {
    const balcony = calculateFloorSize(layout.balcony);
    const main = calculateFloorSize(layout.main);

    return {
        total: {
            width: Math.max(balcony.total.width, main.total.width),
            height: balcony.total.height + main.total.height + stageHeight + balconySeparatorHeight,
        },
        leftWidth: Math.max(balcony.leftWidth, main.leftWidth),
        centerWidth: Math.max(balcony.centerWidth, main.centerWidth),
        rightWidth: Math.max(balcony.rightWidth, main.rightWidth),
    };
});

type AvailableRange = {
    previous : number[];
    next : number[];
};

const modulo = (n : number, d : number) => ((n % d) + d) % d;

export const determineAvailableRange = (
    baseSeatNumber : number,
    seatNumbers : number[],
    row : string,
    event : ReservedEvent,
    reservation : Reservation | undefined,
    desiredTickets : number,
    wrapAround ?: boolean,
) : AvailableRange => {
    const baseIndex = seatNumbers.findIndex(seatNumber => seatNumber === baseSeatNumber);

    if (baseIndex === -1) {
        throw new Error('Base seat number does not exist in seat number set');
    }

    const previous = [];
    const lowerLimiter = wrapAround ? -(seatNumbers.length - (baseIndex + 1)) : 0;

    for (let i = baseIndex - 1; i >= lowerLimiter; --i) {
        const seatNumber = seatNumbers[modulo(i, seatNumbers.length)];
        const seat = event.seats.get(`${row}-${seatNumber}`);

        if (seat?.reserved || (reservation?.seats.get(`${row}-${seatNumber}`))) {
            break;
        }

        previous.push(seatNumber);

        if (previous.length === desiredTickets - 1) {
            break;
        }
    }

    const next = [];
    const upperLimit = wrapAround ? seatNumbers.length + baseIndex : seatNumbers.length;

    for (let i = baseIndex + 1; i < upperLimit; ++i) {
        const seatNumber = seatNumbers[modulo(i, seatNumbers.length)];
        const seat = event.seats.get(`${row}-${seatNumber}`);

        if (seat?.reserved || (reservation?.seats.get(`${row}-${seatNumber}`))) {
            break;
        }

        next.push(seatNumber);

        if (next.length === desiredTickets - 1) {
            break;
        }
    }

    return {previous, next};
};

export const selectFromRange = (range : AvailableRange, desiredTickets : number) : number[] => {
    const idealPrevious = Math.floor((desiredTickets - 1) / 2);
    const idealNext = Math.ceil((desiredTickets - 1) / 2);

    if (range.previous.length < idealPrevious) {
        return [...new Set([
            ...range.previous,
            ...range.next.slice(0, desiredTickets - range.previous.length - 1),
        ])];
    }

    if (range.next.length < idealNext) {
        return [...new Set([
            ...range.next,
            ...range.previous.slice(0, desiredTickets - range.next.length - 1),
        ])];
    }

    return [...new Set([
        ...range.previous.slice(0, idealPrevious),
        ...range.next.slice(0, idealNext),
    ])];
};
