import {Container, LinearProgress, Link, Typography} from '@mui/material';
import {lazy, Suspense} from 'react';
import type {ReactNode} from 'react';
import type MarkdownType from 'react-markdown';
import {useParams} from 'react-router-dom';
import {usePostCheckoutInstructionQuery} from '@/queries/post-checkout-instruction.js';

const Markdown = lazy(async () => {
    return await import('react-markdown') as {default : typeof MarkdownType};
});

type Params = {
    cartId : string;
};

export const CheckoutSuccess = () : ReactNode => {
    const {cartId} = useParams<Params>();

    if (!cartId) {
        throw new Error('Cart ID missing');
    }

    const instructionQuery = usePostCheckoutInstructionQuery(cartId);

    return (
        <Container sx={{py: 3}}>
            <Typography variant="h4" sx={{mb: 2}}>Order received</Typography>

            {!instructionQuery.data && <LinearProgress/>}

            {instructionQuery.data && (
                <Suspense fallback={<LinearProgress/>}>
                    <Markdown
                        skipHtml
                        allowedElements={[
                            'br',
                            'p',
                            'strong',
                            'a',
                            'ul',
                            'li',
                        ]}
                        components={{
                            p: props => (
                                <Typography paragraph {...props} ref={undefined}/>
                            ),
                            a: props => (
                                <Link {...props} color="primary" target="_blank" rel="noreferrer" ref={undefined}/>
                            ),
                        }}
                        unwrapDisallowed
                    >
                        {instructionQuery.data}
                    </Markdown>
                </Suspense>
            )}
        </Container>
    );
};

export default CheckoutSuccess;
