import type {UseMutationResult} from '@tanstack/react-query';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import useOidcFetch from '@/hooks/useOidcFetch.js';
import {mapRawToCart} from '@/mappers/cart.js';
import type {Address} from '@/types/address';
import type {Cart, RawCart} from '@/types/cart.js';
import type {JsonApiDocument, JsonApiError} from '@/types/json-api.js';
import {apiUrl} from '@/utils/api.js';
import {SubmissionError} from '@/utils/errors';

export const useCreateCartMutation = () : UseMutationResult<Cart, Error, void> => {
    const queryClient = useQueryClient();
    const fetch = useOidcFetch();

    return useMutation(async () => {
        const response = await fetch(apiUrl('/carts').toString(), {method: 'POST'});

        if (!response.ok) {
            throw new Error('Failed to create cart');
        }

        const raw = await response.json() as JsonApiDocument<RawCart>;
        return mapRawToCart(raw.data);
    }, {
        onSuccess: cart => {
            queryClient.setQueryData(['carts', cart.id], cart);
        },
    });
};

type AddReservedEventToCartValues = {
    type : 'reserved-event';
    reservationId : string;
};

type AddGeneralAdmissionEventToCartValues = {
    type : 'general-admission-event';
    eventId : string;
    numberOfTickets : number;
};

type AddCourseClassToCartValues = {
    type : 'course-class';
    courseClassId : string;
    studentId : string;
    instrumentId : string | null;
    paidInInstallments : boolean;
};

export type AddToCartValues =
    | AddReservedEventToCartValues
    | AddGeneralAdmissionEventToCartValues
    | AddCourseClassToCartValues;

type AddToCartResultMeta = {
    lineItemId ?: string;
};

export const useAddToCartMutation = (cartId : string) : UseMutationResult<
    [Cart, string | undefined],
    Error,
    AddToCartValues
> => {
    const queryClient = useQueryClient();
    const fetch = useOidcFetch();

    return useMutation(async values => {
        const response = await fetch(apiUrl(`/carts/${cartId}/line-items`).toString(), {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to add item to cart');
        }

        const raw = await response.json() as JsonApiDocument<RawCart, AddToCartResultMeta>;
        return [mapRawToCart(raw.data), raw.meta.lineItemId];
    }, {
        onSuccess: async ([cart]) => {
            queryClient.setQueryData(['carts', cart.id], cart);
            await queryClient.invalidateQueries(['carts', cart.id]);
        },
    });
};

type UpdateNumberOfTicketsValues = {
    tickets : number;
};

export const useUpdateGeneralAdmissionLineItemMutation = (
    cartId : string,
    itemId : string,
) : UseMutationResult<Cart, Error, UpdateNumberOfTicketsValues> => {
    const queryClient = useQueryClient();
    const fetch = useOidcFetch();

    return useMutation(async values => {
        const response = await fetch(
            apiUrl(`/carts/${cartId}/line-items/general-admission-event/${itemId}`).toString(),
            {
                method: 'PUT',
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        if (!response.ok) {
            throw new Error('Failed to update line item');
        }

        const raw = await response.json() as JsonApiDocument<RawCart>;
        return mapRawToCart(raw.data);
    }, {
        onSuccess: async cart => {
            queryClient.setQueryData(['carts', cart.id], cart);
            await queryClient.invalidateQueries(['carts', cart.id]);
        },
    });
};

type AssignStudentValues = {
    studentId : string;
    instrumentId : string | null;
    paidInInstallments : boolean;
};

export const useUpdateCourseClassLineItemMutation = (
    cartId : string,
    itemId : string,
) : UseMutationResult<Cart, Error, AssignStudentValues> => {
    const queryClient = useQueryClient();
    const fetch = useOidcFetch();

    return useMutation(async values => {
        const response = await fetch(apiUrl(`/carts/${cartId}/line-items/course-class/${itemId}`).toString(), {
            method: 'PUT',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to update line item');
        }

        const raw = await response.json() as JsonApiDocument<RawCart>;
        return mapRawToCart(raw.data);
    }, {
        onSuccess: async cart => {
            queryClient.setQueryData(['carts', cart.id], cart);
            await queryClient.invalidateQueries(['carts', cart.id]);
        },
    });
};

type RemoveFromCartValues = {
    itemId : string;
};

export const useRemoveFromCartMutation = (cartId : string) : UseMutationResult<Cart, Error, RemoveFromCartValues> => {
    const queryClient = useQueryClient();
    const fetch = useOidcFetch();

    return useMutation(async values => {
        const response = await fetch(apiUrl(`/carts/${cartId}/line-items/${values.itemId}`).toString(), {
            method: 'DELETE',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to remove item from cart');
        }

        const raw = await response.json() as JsonApiDocument<RawCart>;
        return mapRawToCart(raw.data);
    }, {
        onSuccess: async cart => {
            queryClient.setQueryData(['carts', cart.id], cart);
            await queryClient.invalidateQueries(['carts', cart.id]);
        },
    });
};

type ApplyPromoCodeValues = {
    code : string;
};

export const useApplyPromoCodeMutation = (cartId : string) : UseMutationResult<Cart, Error, ApplyPromoCodeValues> => {
    const queryClient = useQueryClient();
    const fetch = useOidcFetch();

    return useMutation(async values => {
        const response = await fetch(apiUrl(`/carts/${cartId}/promo-code`).toString(), {
            method: 'PUT',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            const error = await response.json() as JsonApiError;
            throw new Error(error.message);
        }

        const raw = await response.json() as JsonApiDocument<RawCart>;
        return mapRawToCart(raw.data);
    }, {
        onSuccess: async cart => {
            queryClient.setQueryData(['carts', cart.id], cart);
            await queryClient.invalidateQueries(['carts', cart.id]);
        },
    });
};

type ApplyGiftCertificateValues = {
    code : string;
};

export const useApplyGiftCertificateMutation = (
    cartId : string
) : UseMutationResult<Cart, Error, ApplyGiftCertificateValues> => {
    const queryClient = useQueryClient();
    const fetch = useOidcFetch();

    return useMutation(async values => {
        const response = await fetch(apiUrl(`/carts/${cartId}/gift-certificate`).toString(), {
            method: 'PUT',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            const error = await response.json() as JsonApiError;
            throw new Error(error.message);
        }

        const raw = await response.json() as JsonApiDocument<RawCart>;
        return mapRawToCart(raw.data);
    }, {
        onSuccess: async cart => {
            queryClient.setQueryData(['carts', cart.id], cart);
            await queryClient.invalidateQueries(['carts', cart.id]);
        },
    });
};

type ApplyMembershipDonationValues = {
    amount : number | null;
};

export const useApplyMembershipDonationMutation = (
    cartId : string
) : UseMutationResult<Cart, Error, ApplyMembershipDonationValues> => {
    const queryClient = useQueryClient();
    const fetch = useOidcFetch();

    return useMutation(async values => {
        const response = await fetch(apiUrl(`/carts/${cartId}/donation`).toString(), {
            method: 'PUT',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to apply membership donation');
        }

        const raw = await response.json() as JsonApiDocument<RawCart>;
        return mapRawToCart(raw.data);
    }, {
        onSuccess: async cart => {
            queryClient.setQueryData(['carts', cart.id], cart);
            await queryClient.invalidateQueries(['carts', cart.id]);
        },
    });
};

type SubmitCartValues = {
    address : Address;
    opaqueAuthorizeNetData : {
        descriptor : string;
        value : string;
    } | null;
    paymentProfileId : string | null;
};

export const useSubmitCartMutation = (
    cartId : string
) : UseMutationResult<void, Error, SubmitCartValues> => {
    const queryClient = useQueryClient();
    const fetch = useOidcFetch();

    return useMutation(async values => {
        const response = await fetch(apiUrl(`/carts/${cartId}/submit`).toString(), {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            const error = await response.json() as JsonApiError;
            throw new SubmissionError(error.message);
        }
    }, {
        onSuccess: () => {
            queryClient.removeQueries(['carts', cartId]);
        },
    });
};
