import type {ReactNode} from 'react';
import {useState} from 'react';

type RenderDialogProps = {
    open : boolean;
    onClose : () => void;
};

type RenderButtonProps = {
    onClick : () => void;
};

type Props = {
    renderDialog : (props : RenderDialogProps) => ReactNode;
    renderButton : (props : RenderButtonProps) => ReactNode;
};

const DialogController = ({renderDialog, renderButton} : Props) : ReactNode => {
    const [dialogOpen, setDialogOpen] = useState(false);

    return (
        <>
            {renderButton({
                onClick: () => {
                    setDialogOpen(true);
                },
            })}

            {renderDialog({
                open: dialogOpen,
                onClose: () => {
                    setDialogOpen(false);
                },
            })}
        </>
    );
};

export default DialogController;
