import {Collapse, FormControlLabel, Stack} from '@mui/material';
import Typography from '@mui/material/Typography';
import {RhfCheckbox, RhfTextField} from 'mui-rhf-integration';
import {useEffect} from 'react';
import type {UseFormReturn} from 'react-hook-form';
import AddressFieldset from '@/components/AddressFieldset';
import type {GiftCertificateFormValues} from '@/pages/GiftCertificate/GiftCertificateForm/GiftCertificateForm';
import type {ShippingOption} from '@/types/gift-certificate';

type Props = {
    form : UseFormReturn<GiftCertificateFormValues>;
    shippingOption : ShippingOption;
};

const ShippingInformation = ({form, shippingOption} : Props) : JSX.Element => {
    useEffect(() => {
        const subscription = form.watch((value, {name}) => {
            if (name !== 'shippingInformation.shippingAddressSameAsBillingAddress') {
                return;
            }

            if (value.deliveryOption !== 'toCustomerByMail' || !value.shippingInformation) {
                return;
            }

            if (value.shippingInformation.shippingAddressSameAsBillingAddress) {
                form.resetField('shippingInformation.shippingAddress');
                form.clearErrors('shippingInformation.shippingAddress');
                return;
            }

            form.setValue('shippingInformation.shippingAddress', {
                firstName: '',
                lastName: '',
                addressLineOne: '',
                addressLineTwo: '',
                city: '',
                state: '',
                zipCode: '',
                countryCode: '',
            });
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [form.watch, form.resetField, form.clearErrors, form.setValue, shippingOption]);

    return (
        <>
            <Typography variant="h6" sx={{mt: 2, mb: 1}}>Shipping Options</Typography>
            {shippingOption === 'toRecipientByMail' && (
                <Stack spacing={2}>
                    <Typography>
                        You've chosen to have us print and mail the certificate to the recipient,
                        so we'll need to gather the shipping information below.
                        The certificate will be printed and mailed first class within the next 2 business days.
                    </Typography>
                    <AddressFieldset
                        prefix="shippingInformation.recipientAddress"
                        form={form}
                    />
                </Stack>
            )}
            {shippingOption === 'toRecipientByEmail' && (
                <>
                    <Typography sx={{mb: 2}}>
                        You've chosen to have us email the certificate to the recipient,
                        so we'll need to gather an email address below. The certificate will be
                        generated and emailed within a couple minutes - we'll bcc you on the email,
                        so that you know it's been sent.
                    </Typography>
                    <RhfTextField
                        control={form.control}
                        label="Recipient Email Address"
                        name="shippingInformation.recipientEmailAddress"
                        required
                        fullWidth
                    />
                </>
            )}
            {shippingOption === 'toCustomerByMail' && (
                <>
                    <Typography>
                        You've chosen to have us print and mail the certificate to you.
                        If you'd like it mailed to an address other than the billing address above,
                        please enter the shipping information below. The certificate will be printed
                        and mailed first class within the next 2 business days.
                    </Typography>
                    <FormControlLabel
                        control={
                            <RhfCheckbox
                                control={form.control}
                                name="shippingInformation.shippingAddressSameAsBillingAddress"
                            />
                        }
                        label="Shipping address same as billing address"
                    />
                    <Collapse in={!form.watch('shippingInformation.shippingAddressSameAsBillingAddress')} mountOnEnter>
                        <AddressFieldset prefix="shippingInformation.shippingAddress" form={form}/>
                    </Collapse>
                </>
            )}
            {shippingOption === 'toCustomerByEmail' && (
                <Typography>
                    You've chosen to have us email the certificate to you - we'll use your billing email address.
                    The certificate will be generated and emailed within a couple minutes.
                </Typography>
            )}
        </>
    );
};

export default ShippingInformation;
