import {DayOfWeek, LocalDate, LocalTime} from '@js-joda/core';
import {mapRawToPointInTime} from '@/mappers/generic';
import type {CourseClass, RawCourseClass} from '@/types/course-class.js';

export const mapRawToCourseClass = (raw : RawCourseClass) : CourseClass => {
    return {
        ...raw,
        saleStart: mapRawToPointInTime(raw.saleStart),
        saleEnd: mapRawToPointInTime(raw.saleEnd),
        daysOfWeek: raw.daysOfWeek.map(value => DayOfWeek.valueOf(value)).sort((a, b) => a.compareTo(b)),
        startDate: LocalDate.parse(raw.startDate),
        endDate: LocalDate.parse(raw.endDate),
        startTime: LocalTime.parse(raw.startTime),
        endTime: LocalTime.parse(raw.endTime),
        minDateOfBirth: raw.minDateOfBirth ? LocalDate.parse(raw.minDateOfBirth) : null,
        maxDateOfBirth: raw.maxDateOfBirth ? LocalDate.parse(raw.maxDateOfBirth) : null,
    };
};
