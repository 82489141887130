import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {useNavigate} from 'react-router-dom';

const Success = () : JSX.Element => {
    const navigate = useNavigate();
    return (
        <>
            <Typography>Thank you for your gift certificate purchase</Typography>
            <Button
                onClick={() => {
                    navigate({
                        pathname: '/gift',
                    });
                }}
            >
                Go Back
            </Button>
        </>
    );
};

export default Success;
