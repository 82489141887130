import {useAuth0} from '@auth0/auth0-react';
import {useCallback} from 'react';
import {useLocation} from 'react-router-dom';

export class LoginRedirectError extends Error {}

export default () : typeof fetch => {
    const {getAccessTokenSilently, loginWithRedirect} = useAuth0();
    const location = useLocation();

    return useCallback(async (input : RequestInfo | URL, init ?: RequestInit) => {
        let accessToken;

        try {
            accessToken = await getAccessTokenSilently();
        } catch {
            await loginWithRedirect({appState: {
                returnTo: `${location.pathname}${location.search}${location.hash}`,
            }});
            throw new LoginRedirectError('Refresh token missing, performing login redirect');
        }

        if (!init) {
            init = {};
        }

        init.headers = new Headers(init.headers);
        init.headers.set('Authorization', `Bearer ${accessToken}`);

        return await fetch(input as Request | string, init);
    }, [getAccessTokenSilently, loginWithRedirect, location]);
};
