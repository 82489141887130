import {Collapse, FormControlLabel, Stack, Typography} from '@mui/material';
import {RhfCheckbox} from 'mui-rhf-integration';
import type {ReactNode} from 'react';
import type {UseFormReturn} from 'react-hook-form';
import type {DonationFormValues} from './Donate.js';
import AddressFieldset from '@/components/AddressFieldset/index.js';
import CreditCardFieldset from '@/components/CreditCardFieldset/index.js';

type Props = {
    form : UseFormReturn<DonationFormValues>;
};

const PaymentInformation = ({form} : Props) : ReactNode => {
    const billingAddressSameAsPersonalAddress = form.watch('billingAddressSameAsPersonalAddress');

    return (
        <Stack spacing={2} sx={{mt: 2}}>
            <Typography variant="h6" sx={{mt: 2}}>Payment Information</Typography>
            <CreditCardFieldset form={form} prefix="creditCard"/>
            <FormControlLabel
                control={
                    <RhfCheckbox
                        control={form.control}
                        name="billingAddressSameAsPersonalAddress"
                        edge="start"
                    />
                }
                label="Billing address same as personal address"
            />
            <Collapse in={!billingAddressSameAsPersonalAddress} unmountOnExit>
                <AddressFieldset prefix="billingAddress" form={form}/>
            </Collapse>
        </Stack>
    );
};

export default PaymentInformation;
