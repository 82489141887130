import type {ReactNode} from 'react';
import BlockSelector from './BlockSelector.js';
import {blockSpacing, calculateBlockSize} from './utils.js';
import type {Block} from '@/seating-layouts/index.js';

type Props = {
    x : number;
    y : number;
    blocks : Block[];
};

const FloorSelector = ({x, y, blocks} : Props) : ReactNode => {
    let blockY = 0;

    return (
        <g transform={`translate(${x}, ${y})`}>
            {blocks.map((block, index) => {
                const blockSelector = (
                    <BlockSelector
                        key={index}
                        x={0}
                        y={blockY}
                        block={block}
                    />
                );
                const blockSize = calculateBlockSize(block);
                blockY += blockSize.total.height + blockSpacing;

                return blockSelector;
            })}
        </g>
    );
};

export default FloorSelector;
