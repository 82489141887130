import CloseIcon from '@mui/icons-material/Close';
import {Box, Dialog, Fab, useMediaQuery, useTheme} from '@mui/material';
import type {ReactNode} from 'react';
import {useState} from 'react';
import {TransformComponent, TransformWrapper} from 'react-zoom-pan-pinch';
import SeatingChart from '@/components/SeatingChart/index.js';
import type {SeatingChartProps} from '@/components/SeatingChart/SeatingChart.js';
import type {ReservedEvent} from '@/types/event.js';
import type {Reservation} from '@/types/reservation.js';

type Props = {
    desiredTickets : number;
    event : ReservedEvent;
    reservation ?: Reservation;
};

const SeatingChartContainer = ({desiredTickets, event, reservation} : Props) : ReactNode => {
    const theme = useTheme();
    const useDialogSelector = useMediaQuery(theme.breakpoints.down('md'));
    const [showDialog, setShowDialog] = useState(false);

    const seatingChartProps : SeatingChartProps = {
        event,
        desiredTickets,
        reservation,
    };

    if (!useDialogSelector) {
        return (
            <SeatingChart
                {...seatingChartProps}
                interactive
            />
        );
    }

    return (
        <>
            <SeatingChart
                {...seatingChartProps}
                onClick={() => {
                    setShowDialog(true);
                }}
            />

            <Dialog open={showDialog} fullScreen>
                <TransformWrapper
                    centerOnInit
                    maxScale={8}
                >
                    <TransformComponent
                        wrapperStyle={{
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <Box sx={{p: 4}}>
                            <SeatingChart
                                {...seatingChartProps}
                                interactive
                                applySize
                                onSelect={(numTotalSeats, numNewSeats) => {
                                    if (numNewSeats === desiredTickets) {
                                        setShowDialog(false);
                                    }
                                }}
                            />
                        </Box>
                    </TransformComponent>
                </TransformWrapper>

                <Fab
                    sx={{
                        position: 'absolute',
                        right: 16,
                        bottom: 16,
                    }}
                    onClick={() => {
                        setShowDialog(false);
                    }}
                >
                    <CloseIcon/>
                </Fab>
            </Dialog>
        </>
    );
};

export default SeatingChartContainer;
