import type {UseQueryResult} from '@tanstack/react-query';
import {useQuery} from '@tanstack/react-query';
import useOidcFetch from '@/hooks/useOidcFetch.js';
import type {JsonApiDocument} from '@/types/json-api.js';
import type {PaymentProfile} from '@/types/payment-profile.js';
import {apiUrl} from '@/utils/api.js';

export const usePaymentProfilesQuery = () : UseQueryResult<PaymentProfile[]> => {
    const fetch = useOidcFetch();

    return useQuery(['payment-profiles'], async ({signal}) => {
        const response = await fetch(apiUrl('/payment-profiles').toString(), {
            signal,
        });

        if (!response.ok) {
            throw new Error('Unable to fetch payment profiles');
        }

        const raw = await response.json() as JsonApiDocument<PaymentProfile[]>;
        return raw.data;
    });
};
