import {FormHelperText} from '@mui/material';
import type {ReactNode} from 'react';
import type {Control, FieldPath, FieldValues} from 'react-hook-form';
import {Controller} from 'react-hook-form';

type Props<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
    control : Control<TFieldValues>;
    name : TName;
};

const FieldError = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({control, name} : Props<TFieldValues, TName>) : ReactNode => {
    return (
        <Controller
            control={control}
            name={name}
            render={({fieldState}) => {
                if (fieldState.error) {
                    return (
                        <FormHelperText error>{fieldState.error.message}</FormHelperText>
                    );
                }

                return <></>;
            }}
        />
    );
};

export default FieldError;
