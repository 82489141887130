import {useAuth0} from '@auth0/auth0-react';
import LogoutIcon from '@mui/icons-material/Logout';
import PaymentIcon from '@mui/icons-material/Payment';
import {Avatar, Box, Button, Divider, IconButton, ListItemIcon, Menu, MenuItem, Skeleton} from '@mui/material';
import {bindMenu, bindTrigger, usePopupState} from 'material-ui-popup-state/hooks';
import type {ReactNode} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

const UserMenu = () : ReactNode => {
    const {user, isLoading, loginWithRedirect, logout} = useAuth0();
    const popupState = usePopupState({variant: 'popover', popupId: 'userMenu'});
    const location = useLocation();
    const navigate = useNavigate();

    if (isLoading) {
        return (
            <Skeleton variant="circular" width={40} height={40}/>
        );
    }

    if (!user) {
        return (
            <Button
                onClick={() => {
                    void loginWithRedirect({appState: {
                        returnTo: `${location.pathname}${location.search}${location.hash}`,
                    }});
                }}
            >
                Sign in
            </Button>
        );
    }

    return (
        <>
            <IconButton edge="end" {...bindTrigger(popupState)}>
                <Avatar alt={user.email} src={user.picture}/>
            </IconButton>

            <Menu {...bindMenu(popupState)}>
                <Box sx={{px: 2, py: 1}}>
                    {user.email}
                </Box>

                <Divider sx={{my: 1}}/>

                <MenuItem onClick={() => {
                    navigate('/payment-profiles');
                    popupState.close();
                }}>
                    <ListItemIcon><PaymentIcon/></ListItemIcon>
                    Payment profiles
                </MenuItem>

                <Divider sx={{my: 1}}/>

                <MenuItem onClick={() => {
                    void logout({logoutParams: {returnTo: window.location.origin}});
                }}>
                    <ListItemIcon><LogoutIcon/></ListItemIcon>
                    Sign out
                </MenuItem>
            </Menu>
        </>
    );
};

export default UserMenu;
