import type {AppState} from '@auth0/auth0-react';
import {Auth0Provider} from '@auth0/auth0-react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {createTheme, CssBaseline} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {SnackbarProvider} from 'notistack';
import {StrictMode} from 'react';
import {ConfirmProvider} from 'react-confirm-hook';
import {createRoot} from 'react-dom/client';
import {RouterProvider} from 'react-router-dom';
import {router} from '@/router.js';

const theme = createTheme({
    palette: {
        primary: {
            main: '#46509a',
        },
        secondary: {
            main: '#f59e0b',
        },
    },
});

const queryClient = new QueryClient();

const container = document.getElementById('root');

if (!container) {
    throw new Error('Root element missing');
}

const redirectCallback = (appState ?: AppState) => {
    void router.navigate(appState?.returnTo ?? '/');
};

const root = createRoot(container);
root.render((
    <StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Auth0Provider
                domain={import.meta.env.VITE_APP_AUTH0_DOMAIN}
                clientId={import.meta.env.VITE_APP_AUTH0_CLIENT_ID}
                useRefreshTokens
                authorizationParams={{
                    redirect_uri: window.location.origin,
                    audience: import.meta.env.VITE_APP_AUTH0_AUDIENCE,
                }}
                onRedirectCallback={redirectCallback}
                cacheLocation="localstorage"
            >
                <QueryClientProvider client={queryClient}>
                    <SnackbarProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <ConfirmProvider>
                                <RouterProvider router={router}/>
                            </ConfirmProvider>
                        </LocalizationProvider>
                    </SnackbarProvider>
                </QueryClientProvider>
            </Auth0Provider>
        </ThemeProvider>
    </StrictMode>
));
