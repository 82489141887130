import type {DialogProps} from '@mui/material';
import {Dialog, styled} from '@mui/material';
import type {JSXElementConstructor, ReactNode} from 'react';
import {useCallback, useState} from 'react';
import type {FieldValues, SubmitHandler} from 'react-hook-form';

export const DialogForm = styled('form')({
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
});

export type FormDialogFormProps<TFieldValues extends FieldValues> = {
    wrapSubmit : (submitHandler : SubmitHandler<TFieldValues>) => SubmitHandler<TFieldValues>;
    onClose : () => void;
};

type Props<TFormProps, TFieldValues extends FieldValues> = {
    FormComponent : JSXElementConstructor<TFormProps & FormDialogFormProps<TFieldValues>>;
    formProps : TFormProps;
    dialogProps : Omit<DialogProps, 'open' | 'onClose'>;
    open : boolean;
    onClose : () => void;
};

const FormDialog = <TFormProps, TFieldValues extends FieldValues>(
    {formProps, dialogProps, open, onClose, FormComponent} : Props<TFormProps, TFieldValues>
) : ReactNode => {
    const [submitting, setSubmitting] = useState(false);

    const wrapSubmit = useCallback((
        submitHandler : SubmitHandler<TFieldValues>
    ) : SubmitHandler<TFieldValues> => {
        return async (values : TFieldValues) => {
            setSubmitting(true);

            try {
                return await submitHandler(values);
            } finally {
                setSubmitting(false);
            }
        };
    }, []);

    const handleClose = () => {
        if (submitting) {
            return;
        }

        onClose();
    };

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            {...dialogProps}
            PaperProps={{elevation: 0, ...dialogProps.PaperProps}}
        >
            <FormComponent
                {...formProps}
                wrapSubmit={wrapSubmit}
                onClose={handleClose}
            />
        </Dialog>
    );
};

export default FormDialog;
