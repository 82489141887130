import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {Alert, Card, CardHeader, Divider, IconButton, List, ListSubheader} from '@mui/material';
import Typography from '@mui/material/Typography';
import type {ReactNode} from 'react';
import type {DetailsTabValue} from '../Details/index.js';
import {useApplyMembershipDonationMutation} from '@/mutations/cart.js';
import SummaryLineItem from '@/pages/Checkout/Summary/SummaryLineItem.js';
import type {Cart} from '@/types/cart.js';
import {currencyFormatter} from '@/utils/format.js';

type Props = {
    cart : Cart;
    onItemsEdit : (type : DetailsTabValue) => void;
};

const Summary = ({cart, onItemsEdit} : Props) : ReactNode => {
    const applyMembershipDonationMutation = useApplyMembershipDonationMutation(cart.id);

    const courseClassDebits = cart.summary.debit.filter(debit => debit.type === 'course-class');
    const eventDebits = cart.summary.debit.filter(debit => debit.type === 'event');
    const otherDebits = cart.summary.debit.filter(debit => debit.type !== 'course-class' && debit.type !== 'event');

    return (
        <Card sx={{flexGrow: 1}}>
            <CardHeader title="Summary"/>
            <List>
                {courseClassDebits.length > 0 && (
                    <>
                        <ListSubheader disableSticky sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography variant="body2" sx={{flexGrow: 1}}>Classes</Typography>

                            <IconButton
                                edge="end"
                                onClick={() => {
                                    onItemsEdit('classes');
                                }}
                            >
                                <EditIcon/>
                            </IconButton>
                        </ListSubheader>

                        {courseClassDebits.map(item => (
                            <SummaryLineItem
                                key={item.id}
                                {...item}
                            />
                        ))}
                    </>
                )}

                {eventDebits.length > 0 && (
                    <>
                        <ListSubheader disableSticky sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography variant="body2" sx={{flexGrow: 1}}>Events</Typography>

                            <IconButton
                                edge="end"
                                onClick={() => {
                                    onItemsEdit('events');
                                }}
                            >
                                <EditIcon/>
                            </IconButton>
                        </ListSubheader>

                        {eventDebits.map(item => (
                            <SummaryLineItem
                                key={item.id}
                                {...item}
                            />
                        ))}
                    </>
                )}

                {otherDebits.length > 0 && (
                    <>
                        <ListSubheader disableSticky>
                            Other
                        </ListSubheader>
                        {otherDebits.map(item => {
                            return (
                                <SummaryLineItem
                                    key={item.id}
                                    {...item}
                                    action={
                                        item.type === 'membership-donation'
                                            ? (
                                                <IconButton
                                                    edge="end"
                                                    onClick={() => {
                                                        applyMembershipDonationMutation.mutate({amount: null});
                                                    }}
                                                >
                                                    <RemoveCircleIcon/>
                                                </IconButton>
                                            )
                                            : undefined
                                    }
                                />
                            );
                        })}
                    </>
                )}

                {cart.summary.debit.length > 0 && (
                    <Divider/>
                )}

                {cart.summary.credit.map(item => (
                    <SummaryLineItem key={item.id} {...item}/>
                ))}

                {cart.summary.credit.length > 0 && (
                    <Divider/>
                )}

                <SummaryLineItem {...cart.summary.total}/>

                {cart.summary.total.amount !== cart.summary.dueImmediately.amount && (
                    <SummaryLineItem {...cart.summary.dueImmediately}/>
                )}
            </List>

            {cart.summary.additionalCreditBalance > 0 && (
                <Alert severity="info" sx={{mt: 2}}>
                    {currencyFormatter.format(cart.summary.additionalCreditBalance / 100)} will be added to your account
                    balance.
                </Alert>
            )}
        </Card>
    );
};

export default Summary;
