import type {UseQueryResult} from '@tanstack/react-query';
import {useQuery} from '@tanstack/react-query';
import {mapRawToCourseClass} from '@/mappers/course-class';
import type {CourseClass, RawCourseClass} from '@/types/course-class';
import type {JsonApiDocument} from '@/types/json-api.js';
import {apiUrl} from '@/utils/api.js';

type UseCourseClassOptions = {
    excludeLineItemId ?: string;
};

export const useCourseClassQuery = (
    courseClassId : string,
    options : UseCourseClassOptions = {}
) : UseQueryResult<CourseClass> => {
    const encodedOptions = JSON.stringify(options);

    return useQuery(['course-class', courseClassId, encodedOptions], async ({signal}) => {
        const url = apiUrl(`/course-classes/${courseClassId}`);

        if (options.excludeLineItemId) {
            url.searchParams.set('excludeLineItem', options.excludeLineItemId);
        }

        const response = await fetch(url.toString(), {
            signal,
        });

        if (!response.ok) {
            throw new Error(`Unable to fetch course class with ID "${courseClassId}"`);
        }

        const raw = await response.json() as JsonApiDocument<RawCourseClass>;
        return mapRawToCourseClass(raw.data);
    }, {
        retry: false,
    });
};
