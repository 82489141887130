import {useAuth0} from '@auth0/auth0-react';
import {type ReactNode, useEffect} from 'react';

const Chatlio = () : ReactNode => {
    const {user} = useAuth0();

    useEffect(() => {
        if (user) {
            document.addEventListener('chatlio.ready', () => {
                window._chatlio.identify(user.sub as string, {
                    email: user.email,
                });
            }, false);
        }
    }, [user]);

    return <chatlio-widget widgetid="146cd094-3c61-46cf-55c1-32cdda3b9b3a"/>;
};

export default Chatlio;
