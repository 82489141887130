import {Stack} from '@mui/material';
import {RhfTextField} from 'mui-rhf-integration';
import type {ReactNode} from 'react';
import type {UseFormReturn, FieldPath, FieldPathByValue, FieldValues} from 'react-hook-form';
import {IMask} from 'react-imask';
import {z} from 'zod';
import {createMaskedInput} from '@/utils/masked-input';

export const creditCardSchema = z.object({
    cardNumber: z.string().trim().min(1).transform(value => value.replace(/-/g, '')),
    expirationDate: z.string().trim().regex(/^\d{2}\/\d{2}$/, {message: 'Invalid Expiration Date'}),
    cvv: z.string().trim().regex(/^\d{1,4}$/, {message: 'Invalid CVV'}),
});

export type CreditCard = z.infer<typeof creditCardSchema>;

const MaskedCardNumberInput = createMaskedInput({
    mask: /^\d[\d-]*$/,
});

const MaskedExpirationDateInput = createMaskedInput({
    mask: 'MM/YY',
    lazy: false,
    blocks: {
        YY: {
            mask: '00',
            placeholderChar: 'Y',
        },
        MM: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
            autofix: 'pad',
            placeholderChar: 'M',
        },
    },
});

const MaskedCvvInput = createMaskedInput({
    mask: /^\d{1,4}$/,
});

type Props<
    TFieldValues extends FieldValues,
    TPath extends FieldPathByValue<TFieldValues, CreditCard | undefined>,
> = {
    prefix : TPath;
    form : UseFormReturn<TFieldValues>;
    disabled ?: boolean;
};

const CreditCardFieldset = <
    TFieldValues extends FieldValues,
    TPath extends FieldPathByValue<TFieldValues, CreditCard | undefined>,
>({prefix, form, disabled} : Props<TFieldValues, TPath>) : ReactNode => {
    return (
        <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
            <RhfTextField
                control={form.control}
                label="Card Number"
                name={`${prefix}.cardNumber` as FieldPath<TFieldValues>}
                InputProps={{
                    inputComponent: MaskedCardNumberInput,
                }}
                required={!disabled}
                disabled={disabled}
                sx={{width: {xs: '100%', md: '50%'}}}
                autoComplete="cc-number"
            />
            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} flexBasis="50%">
                <RhfTextField
                    control={form.control}
                    label="Expiration Date"
                    name={`${prefix}.expirationDate` as FieldPath<TFieldValues>}
                    InputProps={{
                        inputComponent: MaskedExpirationDateInput,
                    }}
                    required={!disabled}
                    disabled={disabled}
                    sx={{flexGrow: 1}}
                    InputLabelProps={{shrink: true}}
                    autoComplete="cc-exp"
                />
                <RhfTextField
                    control={form.control}
                    sx={{width: {xs: 'auto', md: 150}}}
                    label="CVV"
                    name={`${prefix}.cvv` as FieldPath<TFieldValues>}
                    InputProps={{
                        inputComponent: MaskedCvvInput,
                    }}
                    required={!disabled}
                    disabled={disabled}
                    autoComplete="cc-csc"
                />
            </Stack>
        </Stack>
    );
};

export default CreditCardFieldset;
