import {Stack} from '@mui/material';
import {RhfTextField} from 'mui-rhf-integration';
import type {ReactNode} from 'react';
import type {FieldPath, FieldPathByValue, FieldValues, UseFormReturn} from 'react-hook-form';
import {z} from 'zod';
import RhfCountrySelect from '@/components/AddressFieldset/RhfCountrySelect.js';

export const addressSchema = z.object({
    firstName: z.string().trim().min(1),
    lastName: z.string().trim().min(1),
    addressLineOne: z.string().trim().min(1),
    addressLineTwo: z.string().trim().default(''),
    city: z.string().trim().min(1),
    state: z.string().trim().default(''),
    zipCode: z.string().trim().min(1),
    countryCode: z.string(),
});

export type Address = z.infer<typeof addressSchema>;

type Props<
    TFieldValues extends FieldValues,
    TPath extends FieldPathByValue<TFieldValues, Address | undefined>,
> = {
    prefix : TPath;
    form : UseFormReturn<TFieldValues>;
};

const AddressFieldset = <
    TFieldValues extends FieldValues,
    TPath extends FieldPathByValue<TFieldValues, Address | undefined>,
>({prefix, form} : Props<TFieldValues, TPath>) : ReactNode => {
    return (
        <Stack spacing={2}>
            <Stack spacing={2} direction={{xs: 'column', sm: 'row'}}>
                <RhfTextField
                    control={form.control}
                    label="First Name"
                    name={`${prefix}.firstName` as FieldPath<TFieldValues>}
                    required
                    sx={{flexBasis: '50%'}}
                    autoComplete="given-name"
                />
                <RhfTextField
                    control={form.control}
                    label="Last Name"
                    name={`${prefix}.lastName` as FieldPath<TFieldValues>}
                    required
                    sx={{flexBasis: '50%'}}
                    autoComplete="family-name"
                />
            </Stack>
            <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                <RhfTextField
                    control={form.control}
                    label="Address Line 1"
                    name={`${prefix}.addressLineOne` as FieldPath<TFieldValues>}
                    required
                    sx={{flexBasis: '50%'}}
                    autoComplete="address-line1"
                />
                <RhfTextField
                    control={form.control}
                    label="Address Line 2"
                    name={`${prefix}.addressLineTwo` as FieldPath<TFieldValues>}
                    sx={{flexBasis: '50%'}}
                    autoComplete="address-line2"
                />
            </Stack>
            <Stack spacing={2} direction={{xs: 'column', md: 'row'}}>
                <RhfTextField
                    control={form.control}
                    label="City"
                    name={`${prefix}.city` as FieldPath<TFieldValues>}
                    required
                    autoComplete="address-level2"
                    sx={{
                        flexBasis: {xs: '100%', md: '50%'},
                    }}
                />
                <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} flexBasis={{xs: '100%', md: '50%'}}>
                    <RhfTextField
                        control={form.control}
                        sx={{flexGrow: 1}}
                        label="State / Province / Region"
                        name={`${prefix}.state` as FieldPath<TFieldValues>}
                        autoComplete="address-level-1"
                    />
                    <RhfTextField
                        control={form.control}
                        sx={{width: {xs: 'auto', md: 150}}}
                        label="ZIP Code"
                        name={`${prefix}.zipCode` as FieldPath<TFieldValues>}
                        required
                        autoComplete="postal-code"
                    />
                </Stack>
            </Stack>

            <RhfCountrySelect
                control={form.control}
                name={`${prefix}.countryCode` as FieldPath<TFieldValues>}
                textFieldProps={{
                    label: 'Country',
                    autoComplete: 'country',
                }}
            />
        </Stack>
    );
};

export default AddressFieldset;
