import {Divider, Stack} from '@mui/material';
import Typography from '@mui/material/Typography';
import type {ReactNode} from 'react';
import {Fragment} from 'react';
import GeneralAdmissionEventItem from './GeneralAdmissionEventItem.js';
import ReservedEventItem from './ReservedEventItem.js';
import type {Cart, EventLineItem} from '@/types/cart.js';

type Props = {
    cart : Cart;
    items : EventLineItem[];
};

const Events = ({cart, items} : Props) : ReactNode => {
    if (items.length === 0) {
        return (
            <Typography sx={{p: 2}}>You have not added any events to your cart.</Typography>
        );
    }

    return (
        <Stack>
            {items.map((item, index) => (
                <Fragment key={item.id}>
                    {index > 0 && <Divider/>}

                    {item.type === 'reserved-event'
                        ? <ReservedEventItem key={item.id} cart={cart} item={item}/>
                        : <GeneralAdmissionEventItem key={item.id} cart={cart} item={item}/>
                    }
                </Fragment>
            ))}
        </Stack>
    );
};

export default Events;
