import type {UseQueryResult} from '@tanstack/react-query';
import {useQuery} from '@tanstack/react-query';
import type {JsonApiDocument} from '@/types/json-api.js';
import {apiUrl} from '@/utils/api.js';

export const usePostDonationInstructionQuery = (donationId : string) : UseQueryResult<string> => {
    return useQuery(['post-donation-instructions'], async ({signal}) => {
        const response = await fetch(apiUrl(`/post-donation-instructions/${donationId}`).toString(), {
            signal,
        });

        if (!response.ok) {
            throw new Error('Unable to fetch post donation instructions');
        }

        const raw = await response.json() as JsonApiDocument<string>;
        return raw.data;
    });
};
