import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BalanceIcon from '@mui/icons-material/Balance';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import MailIcon from '@mui/icons-material/Mail';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PersonIcon from '@mui/icons-material/Person';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import {List, ListItem, ListItemButton, ListItemIcon, ListItemText} from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import type {ReactNode} from 'react';

type LinkSpec = {
    label : string;
    url : string;
    icon : ReactNode;
};

const linkSpecs : LinkSpec[] = [
    {
        label: 'Membership',
        url: 'https://www.oldtownschool.org/support/membership/',
        icon: <CardMembershipIcon/>,
    },
    {
        label: 'Corporate & Foundation Gifts',
        url: 'https://www.oldtownschool.org/support/donors/corporate-foundation/',
        icon: <CardGiftcardIcon/>,
    },
    {
        label: 'Employer Matching',
        url: 'https://www.oldtownschool.org/support/employermatching/',
        icon: <BalanceIcon/>,
    },
    {
        label: 'Sponsorship & Advertising',
        url: 'https://www.oldtownschool.org/support/sponsorship/',
        icon: <MonetizationOnIcon/>,
    },
    {
        label: 'Planned Giving',
        url: 'https://www.oldtownschool.org/support/plannedgiving/',
        icon: <CardGiftcardIcon/>,
    },
    {
        label: 'Tribute Gifts',
        url: 'https://www.oldtownschool.org/support/donors/tribute/',
        icon: <CardGiftcardIcon/>,
    },
    {
        label: 'Individual Donors',
        url: 'https://www.oldtownschool.org/support/donors/individual/',
        icon: <PersonIcon/>,
    },
    {
        label: 'The Unbroken Circle',
        url: 'https://www.oldtownschool.org/support/donors/unbrokencircle/',
        icon: <TripOriginIcon/>,
    },
    {
        label: 'Volunteer Opportunities',
        url: 'https://www.oldtownschool.org/volunteers/',
        icon: <EmojiPeopleIcon/>,
    },
    {
        label: 'Annual Reports',
        url: 'https://www.oldtownschool.org/support/annualreport/',
        icon: <AssignmentIcon/>,
    },
    {
        label: 'Strategic Plan 2020',
        url: 'https://www.oldtownschool.org/media/doc/2016/OTS_Plan2020_Web.pdf',
        icon: <PictureAsPdfIcon/>,
    },
    {
        label: 'Contact Us',
        url: 'https://www.oldtownschool.org/contact/',
        icon: <MailIcon/>,
    },
];

const MoreWaysToHelp = () : ReactNode => {
    return (
        <Grid xs={12} md={3}>
            <Typography
                variant="h5"
            >
                More Ways to Help
            </Typography>
            <Typography sx={{my: 2}}>
                As a 501(c)(3) not for profit arts organization,
                your donation to Old Town School is tax deductible to the extent allowable by law.
            </Typography>
            <List disablePadding>
                {linkSpecs.map(item => {
                    return (
                        <ListItem disablePadding key={item.label}>
                            <ListItemButton
                                component="a"
                                href={item.url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText>{item.label}</ListItemText>
                                <ArrowForwardIosIcon/>
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
        </Grid>
    );
};

export default MoreWaysToHelp;
